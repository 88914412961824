module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Webski Gość - Front-end Developer","short_name":"Webski Gość","description":"Szukasz kogoś, kto zaprojektuje i stworzy dla Ciebie stronę internetową, sklep lub pomoże przy rozwijaniu biznesu online? Zadzwoń do mnie: +48 791 406 404","start_url":"/","background_color":"#fff","theme_color":"#4d55a1","lang":"pl","display":"minimal-ui","icon":"/Users/webskigosc/Dev/webskigosc-pl/static/assets/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c239d883a597de4049fc9c8cd5959c7c"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
